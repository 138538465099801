export const IMG = (imgName) => {
    return require(`./images/${imgName}`)
}

export const AUDIO = (audioName) => {
    return require(`./audio/${audioName}`)
}

export const sxFabApp = {
    backgroundColor:'rgba(32,35,30,0.1)',
    border:2,
    borderColor:'transparent',
    position: 'fixed',
    color:'#FFFFFF',
    boxShadow:'none',
    "&:hover": {
      backgroundColor: "#5c5f5b",
      color:'#FFFFFF'
    }
}

export const sxFabAppFullbg = {
    backgroundColor:'#FFFFFF',
    border:2,
    borderColor:'transparent',
    position: 'fixed',
    color:'#FFFFFF',
    boxShadow:'none',
    "&:hover": {
      backgroundColor: "#CDCDCD",
      color:'#FFFFFF'
    }
}

