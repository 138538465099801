import React, { useRef, useEffect} from "react";
import "../style/Timeline.css";

import { gsap } from "gsap";
import { Draggable } from 'gsap/all';
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import * as EnvLalique from '../data/espritlalique';


gsap.registerPlugin(ScrollTrigger, Draggable);

export default function Timeline ({ enableScroll, items, setTotem, itemIdx}) {
  
  

  let trigger= useRef(null);
  let wrap= useRef(null);
  let cards= useRef(null);
  let seamlessLoop= useRef(null);
  
  let currentItem=useRef(-1);

  const cardRef = useRef(null);

  let itemsTimes=[];

  useEffect(() => {
    console.log("enableScroll change:"+enableScroll);
    if (enableScroll) {
      let allTriggers = ScrollTrigger.getAll();
      let position = allTriggers[0].scroll();
      ScrollTrigger.enable();
      allTriggers[0].scroll(position);
    }
    else {
      ScrollTrigger.disable();
    }
  }, [enableScroll]);

  useGSAP(() => {

    const spacing = 0.1;

    const snapTime = gsap.utils.snap(spacing);

    let iteration = 0;
    let playhead = {offset: 0};
    
    function ElementWithZIndexFinder() {      
      
      if(cardRef!==null && cardRef.current!==null) {
        const allElements = cardRef.current.querySelectorAll('li');
        if(allElements) {
          for (const element of allElements) {
            const zIndex = parseInt(window.getComputedStyle(element).getPropertyValue('z-index'));
            if (zIndex >= 90) {
              let totemidx=parseInt(element.getAttribute('name'));
              console.log("ElementWithZIndexFinder "+currentItem.current+" : "+totemidx);
              if(currentItem.current!==totemidx) {
                currentItem.current=totemidx;
                setTotem(element.getAttribute('name'));
              }
              break;
            }
          }
        }
      }
    }

    gsap.set('.gallery', {autoAlpha:1});
    gsap.set('.cards li', {yPercent: 450, opacity: 0, scale: 0});

    cards = gsap.utils.toArray('.cards li');

    seamlessLoop = buildSeamlessLoop(cards, spacing, animateFunc);
    const wrapTime = gsap.utils.wrap(0, seamlessLoop.duration());


    let scrub = gsap.to(playhead, { // we reuse this tween to smoothly scrub the playhead on the seamlessLoop
      offset: 0,
      onUpdate() {
        seamlessLoop.time(wrapTime(playhead.offset)); // convert the offset to a "safe" corresponding time on the seamlessLoop timeline
      },
      duration: 0.5,
      ease: "power3",
      paused: true
    });

    function animateFunc(element, idx) {
      const tl = gsap.timeline();
      tl.fromTo(element, {scale: 0, opacity: 1}, {scale: 1, opacity: 1, zIndex: 100, duration: 0.5, yoyo: true, repeat: 1, ease: "power1.in", immediateRender: false})
        .fromTo(element, {yPercent: 450}, {yPercent: -450, duration: 1, ease: "none", immediateRender: false}, 0);
      return tl;
    };

    function buildSeamlessLoop(items, spacing, animateFunc) {

      let overlap = Math.ceil(1 / spacing); // number of EXTRA animations on either side of the start/end to accommodate the seamless looping
      let startTime = items.length * spacing + 0.5; // the time on the rawSequence at which we'll start the seamless loop
      let loopTime = (items.length + overlap) * spacing + 1; // the spot at the end where we loop back to the startTime
      let rawSequence = gsap.timeline({paused: true}); // this is where all the "real" animations live
      let seamlessLoop = gsap.timeline({ // this merely scrubs the playhead of the rawSequence so that it appears to seamlessly loop
          paused: true,
          repeat: -1, // to accommodate infinite scrolling/looping
          onRepeat() { // works around a super rare edge case bug that's fixed GSAP 3.6.1
            this._time === this._dur && (this._tTime += this._dur - 0.01);
          }
        });
        let l = items.length+ overlap * 2;
        //l=l-(l%items.length);
        //l=items.length;
        //console.log("l:"+l+" lg:"+items.length);
        
        let time, i, index;
        
   // 	// now loop through and create all the animations in a staggered fashion. Remember, we must create EXTRA animations at the end to accommodate the seamless looping.
        for (i = 0; i < l; i++) {
          index = i % items.length;
          time = i * spacing;//+0.6;
          rawSequence.add(animateFunc(items[index], index), time);
          if(i<items.length) {
            itemsTimes.push(time);
          }
          i <= items.length && seamlessLoop.add("label" + i, time);
        }
   //
   // 	// here's where we set up the scrubbing of the playhead to make it appear seamless.
     rawSequence.time(startTime);
     seamlessLoop.to(rawSequence, {
        time: loopTime,
        duration: loopTime - startTime,
        ease: "none"
      }).fromTo(rawSequence, {time: overlap * spacing + 1}, {
        time: startTime,
        duration: startTime - (overlap * spacing + 1),
        immediateRender: false,
        ease: "none"
      });
      return seamlessLoop;
    }
    
    function endScrollListener() {
      scrollToOffset(scrub.vars.offset);
      ElementWithZIndexFinder();
    }
    
    function scrollToOffset(offset) { // moves the scroll playhead to the place that corresponds to the totalTime value of the seamlessLoop, and wraps if necessary.
      //console.log("offset:"+offset);
      let snappedTime = snapTime(offset);
      //console.log("snappedTime:"+snappedTime+" iteration:"+iteration);
      //console.log("seamlessLoop:"+seamlessLoop.duration());
      let progress = (snappedTime - seamlessLoop.duration() * iteration) / seamlessLoop.duration();
      if(isNaN(progress)) return;
      let scroll = progressToScroll(progress);

      
      if (progress >= 1 || progress < 0) {
        return wrap(Math.floor(progress), scroll);
      }
      trigger.scroll(scroll);
    }

    trigger = ScrollTrigger.create({
      start: 0,
      onUpdate(self) {
        let scroll = self.scroll();
        if (scroll > self.end - 1) {
          wrap(1, 1);
        } else if (scroll < 1 && self.direction < 0) {
          wrap(-1, self.end - 1);
        } else {
          scrub.vars.offset = (iteration + self.progress) * seamlessLoop.duration();
          scrub.invalidate().restart();
        }
      },
      end: "+=5000",
      pin: ".gallery"
    });
    ScrollTrigger.config({ ignoreMobileResize: true });
    //console.log("*******");
    //console.log(trigger.pin);

    wrap = (iterationDelta, scrollTo) => {
      iteration += iterationDelta;
      trigger.scroll(scrollTo);
      trigger.update(); // by default, when we trigger.scroll(), it waits 1 tick to update().
    };
    let progressToScroll = progress => gsap.utils.clamp(1, trigger.end - 1, gsap.utils.wrap(0, 1, progress) * trigger.end);
    ScrollTrigger.addEventListener("scrollEnd", endScrollListener);

    window.addEventListener('scrollToTotem', function(evt) {console.log(evt);fctScrollToTotem(evt.totemIdx);});

    function fctScrollToTotem(idx) {
      console.log("fctScrollToTotem itemIdx:"+idx);
      let progress = (itemsTimes[parseInt(idx)-1] - seamlessLoop.duration() * iteration) / seamlessLoop.duration();
      trigger.scroll(progressToScroll(progress));
      seamlessLoop.seek("label"+(parseInt(idx)-1));
    };

    //if(parseInt(itemIdx)!==-1){
    //  fctScrollToTotem(itemIdx);
    //}

    Draggable.create(".drag-proxy", {
      type: "y",
      cursor: 'grab',
      dragClickables:true,
      trigger:  cardRef.current,
      onPress() {
        this.startOffset = scrub.vars.offset;
      },
      onDrag() {
        scrub.vars.offset = this.startOffset + (this.startY - this.y) * 0.001;
        scrub.invalidate().restart(); // same thing as we do in the ScrollTrigger's onUpdate
      },
      onDragEnd() {
        scrollToOffset(scrub.vars.offset);
      }
    });
  }, []);

  return (
    <>
    <div className="gallery">
	    <ul ref={cardRef} className="cards">
          {items.map((te, idx) => {
            return (
              <li aria-label={"Totem Esprit Lalique numéro "+te.id} className="totemelt" key={te.id} name={te.id}>
                <img className="overimg" alt=""/>
                <img src={EnvLalique.IMG(te.image)} alt={te.title}/>
              </li>
            );
          })}
          {items.map((te, idx) => {
            return (
              <li aria-label={"Totem Esprit Lalique numéro "+te.id} className="totemelt" key={te.id} name={te.id}>
                <img className="overimg" alt=""/>
                <img src={EnvLalique.IMG(te.image)} alt={te.title}/>
              </li>
            );
          })}
      </ul>
    </div>
    <div className="drag-proxy"></div>
    </>
  );
};
