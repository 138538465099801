import React, { useRef, useState } from "react";
import '../style/Audio.css';
import ProgressBar from "./ProgressBar";
import AudioControls from "./AudioControls";

export default function AudioPlayer({totemIdx, setAudioText, totem, language}) {

  const progressBarRef = useRef();
  const audioRef = useRef();
  
  const [duration, setDuration] = useState(0);

  console.log("totemIdx in audio:"+totemIdx);

  function updateDuration(time) {
    setDuration(time);
  }
  
  return (
    <div className="audio-container">
      { (totem.type==="audio") ? (
        <div className="audio-time"><ProgressBar progressBarRef={progressBarRef} duration={duration}/></div>
      ) : (null)
      }
        <div className={(totem.type==="audio") ? "audio-player" : "audio-photo"}>
          <AudioControls lang={language} totemIdx={totemIdx} totemElt={totem} audioRef={audioRef} updateDuration={updateDuration} progressBarRef={progressBarRef} setAudioText={setAudioText}/>
        </div>
    </div>
  );
}