import React from "react";

import Fab from '@mui/material/Fab';
import sx from "mui-sx";
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ExitPin } from "../images/close-circle.svg";

import * as EnvLalique from '../data/espritlalique';

import { Tooltip} from 'react-tooltip';

import { useState, useEffect, useRef } from 'react';

import Button from '@mui/material/Button';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import parse from 'html-react-parser';

export default function HelpPage({lang, closeModal}) {

  const MsgScroll={'FR':'<span className="centertext">Sur les pas de René Lalique</span><span>Naviguez sur le parcours et découvrez</span> <span>L\'homme, son époque, son inspiration</span><span>et ses &oelig;uvres.</span>', 
  'EN':'<span className="centertext">In the footsteps of René Lalique</span><span>Navigate the route and discover</span> <span>The man, his time, his inspiration</span><span>and his creations.</span>', 
  'NL':'<span className="centertext">In de voetsporen van René Lalique</span><span>Navigeer door het circuit en ontdek</span> <span>De man, zijn tijd, zijn inspiratie</span><span>en zijn creaties.</span>'};

  const MsgMap={'FR':'<span className="centertext">La carte Esprit Lalique</span><span>Découvrez la carte interactive du parcours</span><span>et les différents points d\'intérêts représentés</span><span>par des pictogrammes</span>',
  'EN':'<span className="centertext">The Esprit Lalique map</span><span>Discover the interactive map of the route</span><span>and the different interests represented</span><span>by pictograms</span>',
  'NL':'<span className="centertext">De kaart van Esprit Lalique</span><span>Ontdek de interactieve kaart van de route</span><span>en de verschillende belangen die worden</span><span>vertegenwoordigd door pictogrammen</span>'  
  }

  const MsgHelp={'FR':'<span className="centertext">Les paramètres du site</span><span>Sélectionner la langue</span><span>Activer le GPS</span><span>Modifier les paramètres d\'accessibilités</span>',
  'EN':'<span className="centertext">Site settings</span><span>Select language</span><span>Activate GPS</span><span>Modify accessibility settings</span>',
  'NL':'<span className="centertext">Site-instellingen</span><span>Selecteer taal</span><span>GPS activeren</span><span>Toegankelijkheidsinstellingen wijzigen</span>'  
  }

  const MsgInfo={'FR':'<span className="centertext">La fiche encyclopédique</span><span>Obtenez les informations détaillées</span><span>(fiche, videos, photos) du point d\'intérêt</span>',
  'EN':'<span className="centertext">The encyclopaedic record</span><span>Get detailed information (fact sheet,</span><span> videos, photos) of the point of interest</span>',
  'NL':'<span className="centertext">Het encyclopedisch verslag</span><span>Gedetailleerde informatie (informatieblad,</span><span> video\'s, foto\'s) van de bezienswaardigheid</span>'
  }
  const tooltipScroll = useRef(null);
  const tooltipMap = useRef(null);
  const tooltipSettings = useRef(null);
  const tooltipInfos = useRef(null);

  const [notooltip, setNoTooltip]=useState(1);

  function startHelpAnimation() {
    console.log("start help scenario");
    setTimeout(function() {tooltipScroll.current?.open();}, 350);
  }
  useEffect(() => {
    window.addEventListener('playHelp', startHelpAnimation);

    return () => {
      window.removeEventListener('playHelp', startHelpAnimation);
    };
  }, []);

  function onNextTooltipClick() {
    let nexttooltip=notooltip+1;
    if(nexttooltip>4) {
      nexttooltip=1;
    }
    switch(nexttooltip) {
      case 1:
        tooltipInfos.current?.close();
        tooltipScroll.current?.open();
        break;
      case 2:
        tooltipScroll.current?.close();
        tooltipMap.current?.open();
        break;
      case 3:
        tooltipMap.current?.close();
        tooltipSettings.current?.open();
        break;  
      case 4:
        tooltipSettings.current?.close();
        tooltipInfos.current?.open();
        break;
      default:
        tooltipInfos.current?.close();
        tooltipScroll.current?.open();
        break;  
    }
    setNoTooltip(nexttooltip);
  }
return (
  <>
   
   <Fab aria-label="Fermer" color="primary" onClick={closeModal} sx={sx(EnvLalique.sxFabAppFullbg, {top: 10,right:10})}>
        <SvgIcon sx={{fontSize:46}}>
          <ExitPin />
        </SvgIcon>
    </Fab>
    <div className="helperzone">
        <div className="helpnext">
          <Button onClick={onNextTooltipClick} style={{
        borderRadius: 35,
        backgroundColor: "#FFFFFF",
        color:"#3e3f3d",
        padding: "10px 20px",
        fontSize: "16px"
    }} variant="contained" endIcon={<KeyboardDoubleArrowRightIcon />}>Suivant</Button>
        </div>
        <div data-tooltip-id="maphelp" className="zonehelpbutton" style={{ top:'10px', left:'10px' }}></div>
        <div data-tooltip-id="settingshelp" className="zonehelpbutton" style={{ bottom:'130px', left:'10px' }}></div>
        <div data-tooltip-id="infohelp" className="zonehelpbutton" style={{ bottom:'130px', right:'10px' }}></div>

        <div data-tooltip-id="scrollcursor" className="helperscroll mouse m-0"></div>
       
        <Tooltip ref={tooltipScroll} id="scrollcursor" place="bottom" imperativeModeOnly="true">
          <div className="tooltiptext">
            {parse(MsgScroll[lang])}
          </div>
        </Tooltip>
        <Tooltip ref={tooltipMap} id="maphelp" place="right-start" imperativeModeOnly="true">
          <div className="tooltiptextleft">
            {parse(MsgMap[lang])}
          </div>
        </Tooltip>
        <Tooltip ref={tooltipSettings} id="settingshelp" place="right-end" imperativeModeOnly="true">
          <div className="tooltiptextleft">
            {parse(MsgHelp[lang])}
          </div>
        </Tooltip>
        <Tooltip ref={tooltipInfos} id="infohelp" place="left-end" imperativeModeOnly="true">
          <div className="tooltiptextleft">
            {parse(MsgInfo[lang])}
          </div>
        </Tooltip>
    </div>
  </>
);
}

