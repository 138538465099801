import "../style/ProgressBar.css";

export default function ProgressBar({ progressBarRef, duration }) {

    const formatTime = (time) => {
        if (time && !isNaN(time)) {
          const minutes = Math.floor(time / 60);
          const formatMinutes =
            minutes < 10 ? `0${minutes}` : `${minutes}`;
          const seconds = Math.floor(time % 60);
          const formatSeconds =
            seconds < 10 ? `0${seconds}` : `${seconds}`;
          return `${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
    };

    return (
      <div className="progress">
        <input ref={progressBarRef} type="range" defaultValue="0" disabled={true}/>
        <span className="time">{formatTime(duration)}</span>
      </div>
    );
  };