import {useLocation } from "react-router-dom"
import { useState, useRef, useEffect} from 'react';
import { isMobile } from 'react-device-detect';

import HelpPage from "./pages/HelpPage"
import Parcours from "./pages/Timeline"
import MapPage from "./pages/MapPage"
import InfosPage from "./pages/InfosPage"

import SettingsPage from "./pages/SettingsPage"
import AudioPlayer from "./pages/AudioPlayer"
import ModalBackdrop from "./pages/ModalBackdrop"
import Sidebar from "./pages/Sidebar"
import sx from "mui-sx";
import Fab from '@mui/material/Fab';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as MapPin } from "./images/map-pin-alt-black.svg";
import { ReactComponent as HelpPin } from "./images/help-circle-black.svg";
import { ReactComponent as SettingsPin } from "./images/settings-black.svg";
import { ReactComponent as InfoPin } from "./images/details-more-black.svg";

import landscapelogo from './images/rotate-device.svg';

import { Transition } from "react-transition-group";


import listTotem from './data/timelineData.json';
import * as EnvLalique from './data/espritlalique';

import ReactGA from 'react-ga4';

import './style/App.css';

const duration = 250;



const rightStyle = {
  transition: `transform ${duration}ms ease-in-out`,
  transform: "translateX(101%)"
};

const leftStyle = {
  transition: `transform ${duration}ms ease-in-out`,
  transform: "translateX(-101%)"
};

const rightTransition = {
  entering: { transform: "translateX(101%)" },
  entered: { transform: "translateX(0)" }
};

const leftTransition = {
  entering: { transform: "translateX(-101%)" },
  entered: { transform: "translateX(0)" }
};


function useScreenOrientation() {
  const [orientation, setOrientation] = useState(window.screen.orientation?window.screen.orientation.type:(window.innerWidth<window.innerHeight)?"portrait-primary":"landscape-primary");

  const handleOrientationChange= () => setOrientation(window.screen.orientation.type);

  const handleWindowSizeChange=() => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if(width<height)
      setOrientation("portrait-primary");
    else
      setOrientation("landscape-primary");

    //console.log("width:"+width+" height:"+height);
  }

  useEffect(() => {
    if(window.screen.orientation) {
      window.screen.orientation.addEventListener('change', handleOrientationChange)
      return () => window.screen.orientation.removeEventListener('change', handleOrientationChange);
    }
    else {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return orientation;
}


function App() {

  const pathname=useLocation().pathname;

  useEffect(() => {
    
    ReactGA.initialize('G-TRFT7TM05M');
    ReactGA.send({ hitType: "pageview", page:  pathname});
    console.log("isMobile:"+detectMobile()+' currentTotemIdx:'+currentTotemIdx);

    if (!detectMobile()) {
      window.location.href = 'https://www.esprit-lalique.com/audioguide/index.html';
    }
    else {
      setPath(pathname);
    }
    
  }, []);
  
  const [currentTotemIdx, setCurrentTotem]=useState(0);
  const [totemElt, setTotem]=useState(listTotem[0]);

  const [currentpath, setPath]=useState("");


  useEffect(() => {
    const eltpath=currentpath.split("/");
    console.log("path:"+currentpath+" lg:"+eltpath.length);

    if(eltpath.length===3) {
      const defaulttotemIdx=parseInt(eltpath[2]);
      console.log("path defaulttotemIdx:"+defaulttotemIdx+" currentTotemIdx:"+currentTotemIdx);
      if(currentTotemIdx!=defaulttotemIdx) {
        console.log("set new value:"+defaulttotemIdx);
        setTotemIndexFromEx(defaulttotemIdx)
        //setCurrentTotem(defaulttotemIdx);
      }
    }
  }, [currentpath]);

  const orientation = useScreenOrientation();

  const [cuesData, setCuesData]=useState(null);

  const [lang, setLanguage]=useState(() => {
    // getting stored value
    const saved = localStorage.getItem("lang");
    //console.log("saved lang:"+saved);
    const initialValue = JSON.parse(saved);
    return initialValue || "FR";
  });

  const [fontsize, setFontSize]=useState(() => {
    // getting stored value
    const saved = localStorage.getItem("fontsize");
    //console.log("saved fontsize:"+saved);
    const initialValue = JSON.parse(saved);
    return parseInt(initialValue) || 0;
  });

  const [gpsActif, setGPS]=useState(() => {
    // getting stored value
    const saved = localStorage.getItem("gps");
    //console.log("saved gps:"+saved);
    const initialValue = JSON.parse(saved);
    return initialValue || false;
  });

  const MsgLandscape={'FR':"Pour une utilisation optimale de l'application, veuillez basculer votre appareil en mode portrait", 'EN':"For best experience using the application, please switch your device to portrait mode.", 'NL':"Voor de beste gebruikservaring van de applicatie zet je je apparaat in portretmodus."};

  
  //console.log("orientation 1:"+orientation+" orientation 2:"+window.screen.orientation.type);

  const [enableScroll, setScroll] = useState(true);

  const [isOpenHelp, toggleIsOpenHelp] = useState(false);
  const [isOpenMap, toggleIsOpenMap] = useState(false);
  const [isOpenSettings, toggleIsOpenSettings] = useState(false);
  const [isOpenInfos, toggleIsOpenInfos] = useState(false);

  const [scrollToItem, setScrollToItem] = useState(-1);

  const nodeMapRef = useRef(null);
  const nodeHelpRef = useRef(null);
  const nodeSetttingsRef = useRef(null);
  const nodeInfosRef = useRef(null);

  
 

  /*useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, []);*/

  useEffect(() => {
    localStorage.setItem("lang", JSON.stringify(lang));
  }, [lang]);
  useEffect(() => {
    localStorage.setItem("fontsize", JSON.stringify(fontsize));
  }, [fontsize]);
  useEffect(() => {
    localStorage.setItem("gps", JSON.stringify(gpsActif));
  }, [gpsActif]);

  function onOpenMapModalClick() {
    setScroll(false);
    toggleIsOpenMap(true);
  }


  function detectMobile() {
    let detectMobile=isMobile;
    if(!detectMobile) {
      detectMobile=(window.innerWidth <= 768);
    }
    return detectMobile;
  }

  function onCloseMapModalClick(moveToTotem) {
    setScrollToItem(moveToTotem);
    toggleIsOpenMap(false);
    setScroll(true);
  }

  function onOpenInfosModalClick() {
    ReactGA.event({
      category: 'User',
      action: 'Open Infos Page Totem:'+(totemElt+1)
    });
    setScroll(false);
    toggleIsOpenInfos(true);
  }

  function onCloseInfosModalClick() {
    toggleIsOpenInfos(false);
    setScroll(true);
  }

  function onOpenHelpModalClick() {
    ReactGA.event({
      category: 'User',
      action: 'Open Help Page'
    });
    setScroll(false);
    toggleIsOpenHelp(true);
  }

  function onCloseHelpModalClick() {
    toggleIsOpenHelp(false);
    setScroll(true);
  }

  function onOpenSettingsModalClick() {
    setScroll(false);
    toggleIsOpenSettings(true);
  }

  function onCloseSettingsModalClick() {
    toggleIsOpenSettings(false);
    setScroll(true);
  }

  function setAppFont(fontsize) {
    //console.log("set font size:"+fontsize);
    ReactGA.event({
      category: 'User',
      action: 'Increase Font:'+fontsize
    });
    setFontSize(fontsize);
  }

  function setAppLang(lang) {
    //console.log("set language:"+lang);
    ReactGA.event({
      category: 'User',
      action: 'Select Language:'+lang
    });
    setLanguage(lang);
  }

  function setAppGPS(gpsActif) {
    //console.log("set GPS:"+gpsActif);
    ReactGA.event({
      category: 'User',
      action: 'Activate GSP'
    });
    setGPS(gpsActif);
  }

  function setTotemIndexFromMap(idx) {
    setTotemIndexFromEx(idx);
  }

  function setTotemIndexFromEx(idx) {
    setTotemIndex(idx);
    const totemEvt = new CustomEvent('scrollToTotem', {
      bubbles: true,
      cancelable: true,
    });
    totemEvt.totemIdx=idx;
    window.dispatchEvent(totemEvt);
  }

  function setTotemIndex(idx) {
      ReactGA.event({
        category: 'User',
        action: 'Select Totem:'+idx
      });
      setCurrentTotem(idx);
      //console.log("select totem no:"+idx+" data:"+listTotem[idx-1]);
      setTotem(listTotem[idx-1]);
  }

  function setAudioText(audioText) {
    setCuesData(audioText);
  }
  
  useEffect(() => {
    //console.log("useEffect orientation 1:"+orientation);
    if(detectMobile()) {
      if (orientation.toLocaleLowerCase()==="portrait-primary") {
        setScroll(true);
      }
      else {
        setScroll(false);
      }
    }
  }, [orientation]);

  return (
    <>
    <div className="bgespritlalique"></div>
    <div className="App" style={{ display: detectMobile() ? 'block' : 'none' }}>
      {detectMobile() ? 
        (<div className={orientation.toLocaleLowerCase()}>
          <div className="infolandscape">
            <p>{MsgLandscape[lang]}</p>
            <img className="landscapeimg" alt="" key="landscape" src={landscapelogo}/>
          </div>
        </div>) : (<div className="portrait-primary"></div>)}
      <Fab aria-label="Carte interactive" color="primary" onClick={onOpenMapModalClick} sx={sx(EnvLalique.sxFabApp, {top: 10,left:10})}>
          <SvgIcon sx={{fontSize:50}}>
            <MapPin />
          </SvgIcon>
      </Fab>
      <Fab aria-label="Aide" color="primary" onClick={onOpenHelpModalClick} sx={sx(EnvLalique.sxFabApp, {top: 10,right:10})}>
          <SvgIcon sx={{fontSize:50}}>
            <HelpPin />
          </SvgIcon>
      </Fab>
      <Fab aria-label="Paramètres" color="primary" onClick={onOpenSettingsModalClick} sx={sx(EnvLalique.sxFabApp, {bottom: 130,left:10})}>
          <SvgIcon sx={{fontSize:50}}>
            <SettingsPin />
          </SvgIcon>
      </Fab>
      <Fab aria-label="Information complémentaire" color="primary" onClick={onOpenInfosModalClick} sx={sx(EnvLalique.sxFabApp, {bottom: 130,right:10})}>
          <SvgIcon sx={{fontSize:50}}>
            <InfoPin />
          </SvgIcon>
      </Fab>

      <Transition onExited={function() {
          console.log("onExited:"+scrollToItem);
          if(scrollToItem!==-1) {
            setTotemIndexFromMap(scrollToItem);
          }
      }} nodeRef={nodeMapRef} in={isOpenMap} timeout={0}>
        {state => (
          <ModalBackdrop duration={duration} state={state} classStyle="modal-backdrop">
            <Sidebar duration={duration} state={state} stylename="fullsidebar" defaultStyle={leftStyle} transitionStyles={leftTransition}>
              {isOpenMap && (
                <MapPage currentTotem={currentTotemIdx} gpsActif={gpsActif} closeModal={onCloseMapModalClick}/>
              )}
            </Sidebar>
          </ModalBackdrop>
        )}
      </Transition>
      
      <Transition nodeRef={nodeInfosRef} in={isOpenInfos} timeout={0}>
        {state => (
          <ModalBackdrop duration={duration} state={state} classStyle="modal-infobackdrop">
            <Sidebar duration={duration} state={state} stylename="infosidebar" defaultStyle={rightStyle} transitionStyles={rightTransition}>
              {isOpenInfos && (
                <InfosPage cuesData={cuesData} totem={totemElt} font={fontsize} closeModal={onCloseInfosModalClick}/>
              )}
            </Sidebar>
          </ModalBackdrop>
        )}
      </Transition>

      <Transition onEntered={function() {
        const helpEvt = new CustomEvent('playHelp', {
          bubbles: true,
          cancelable: true,
        });
        window.dispatchEvent(helpEvt);
      }} nodeRef={nodeHelpRef} in={isOpenHelp} timeout={0}>
        {state => (
          <ModalBackdrop  duration={duration} state={state} classStyle="modal-backdrop">
            <Sidebar duration={duration} state={state} stylename="fulltransparentsidebar" defaultStyle={rightStyle} transitionStyles={rightTransition}>
              {isOpenHelp && (
                <HelpPage lang={lang} closeModal={onCloseHelpModalClick}/>
              )}
            </Sidebar>
          </ModalBackdrop>
        )}
      </Transition>

      <Transition nodeRef={nodeSetttingsRef} in={isOpenSettings} timeout={0}>
        {state => (
          <ModalBackdrop duration={duration} state={state} classStyle="modal-backdrop">
            <Sidebar duration={duration} state={state} stylename="sidebar" defaultStyle={leftStyle} transitionStyles={leftTransition}>
              {isOpenSettings && (
                <SettingsPage setAppLangue={setAppLang} lang={lang} setAppFont={setAppFont} font={fontsize} setAppGPS={setAppGPS} gpsActif={gpsActif} closeModal={onCloseSettingsModalClick}/>
              )}
            </Sidebar>
          </ModalBackdrop>
        )}
      </Transition>

        <div>
          <Parcours enableScroll={enableScroll} items={listTotem} setTotem={setTotemIndex} itemIdx={currentTotemIdx}/>
        </div>
        <AudioPlayer key={currentTotemIdx} totemIdx={currentTotemIdx} setAudioText={setAudioText} totem={totemElt} language={lang}/>
    </div>
    </>
  );
}

export default App;
