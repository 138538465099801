import React from "react";

import Fab from '@mui/material/Fab';
import sx from "mui-sx";
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ExitPin } from "../images/close-circle.svg";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from '@mui/material/Stack';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import * as EnvLalique from '../data/espritlalique';

import { ReactComponent as FontSize } from "../images/font-size.svg";

export default function SettingsPage({setAppLangue, lang, setAppFont, font, setAppGPS, gpsActif, closeModal}) {
  const MsgLang={'FR':"Choix de la langue",'EN':"Select language", "NL":"Taalkeuze"};
  const SizeFont={'FR':"Ajuster la taille de la police",'EN':"Adjust the font size",'NL':"De lettergrootte aanpassen"}

  const handleChange = (event) => {
    setAppLangue(event.target.value);
  };

  const handleChangeGPSOption = (event) => {
    setAppGPS(event.target.checked);
  };

  const marksFont = [
    {
      value: 0
    },
    {
      value: 1
    },
    {
      value: 2
    }
  ];

  const handleSliderChange = (e, newValue) => {
    setAppFont(newValue);
  };

return (
  <>
   <Fab aria-label="Fermer" color="primary" onClick={closeModal} sx={sx(EnvLalique.sxFabApp, {top: 10,right:10})}>
        <SvgIcon sx={{fontSize:45}}>
          <ExitPin />
        </SvgIcon>
    </Fab>
    <div className="optionszone">
      <FormControl sx={{ m: 1, minWidth: 220 }}>
        <InputLabel id="open-select-lang-label">{MsgLang[lang]}</InputLabel>
        <Select
          labelId="open-select-lang-label"
          id="open-select-lang"
          value={lang}
          label="Age"
          onChange={handleChange}
          input={
            <OutlinedInput id="open-select-lang" label={MsgLang[lang]} />
          }
        >
          <MenuItem value={"FR"}>Français</MenuItem>
          <MenuItem value={"EN"}>English</MenuItem>
          <MenuItem value={"NL"}>Nederlandse</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ width: 240}}>
        <Stack alignItems="center" direction="row" gap={0.5}>
          <SvgIcon sx={{fontSize:35}}>
            <FontSize />
          </SvgIcon>
          <p>{SizeFont[lang]}</p>
        </Stack>
        <Box sx={{ pl: 1}}>
          <Slider
            aria-label=""
            value={font}
            marks={marksFont}
            max={2}
            step={1}
            onChangeCommitted={handleSliderChange}
            valueLabelDisplay="off"
          />
        </Box>
      </Box>
      <Box sx={{ width: 240}}>
        <FormControlLabel
            sx={{ ml:0}}
            value="top"
            control={<Switch
              checked={gpsActif} onChange={handleChangeGPSOption} sx={{ transform: "scale(1.5)", ml:2}} color="primary" />}
            label={
              <Typography sx={{ fontSize: 16, fontWeight:600 }}>
                Activer le GPS
              </Typography>
            }
            labelPlacement="start"
        />
      </Box>
    </div>
  </>
);
}

