import React from "react";

import Fab from '@mui/material/Fab';
import sx from "mui-sx";
import SvgIcon from '@mui/material/SvgIcon';
import Carousel from 'react-material-ui-carousel'

import { ReactComponent as ExitPin } from "../images/close-circle.svg";

import * as EnvLalique from '../data/espritlalique';


export default function InfosPage({cuesData, totem, font, closeModal}) {

  const fontvalues=[18, 20, 22];

  return (
  <>
    <Fab aria-label="Fermer" color="primary" onClick={closeModal} sx={sx(EnvLalique.sxFabApp, {bgcolor:"#FFFFFF", top: 10,right:10})}>
        <SvgIcon sx={{fontSize:45}}>
          <ExitPin />
        </SvgIcon>
    </Fab>
    <div className="infodetails">
    <Carousel activeIndicatorIconButtonProps={{style: {color: '#20231e', backgroundColor:'#ffffff'}}} indicatorIconButtonProps={{className:"indicator"}} indicatorContainerProps={{className:"slideindicator"}} navButtonsAlwaysInvisible={true} 
    sx={{minHeight:320, '@media screen and (max-width: 450px)': {
      minHeight:280,
    }, '@media screen and (max-width: 420px)': {
      minHeight:270,
    },
    '@media screen and (max-width: 390px)': {
      minHeight:260,
    },
    '@media screen and (max-width: 380px)': {
      minHeight:250,
    },
    '@media screen and (max-width: 360px)': {
      minHeight:240,
    },
    '@media screen and (max-width: 250)': {
      minHeight:180,
    }
    }} animation="fade" interval={8000} swipe={false}>
          {
            totem.sliders.map( function(item, idx) {
              return (<img className="imgslide" key={idx} src={EnvLalique.IMG(item+".jpg")} alt={"img"+idx}/>)
            })
          }
      </Carousel>
      <div className={(totem.type==="audio")?"infoTexte":"infoTextePhoto"} style={{fontSize:fontvalues[font]}}>
      { (totem.type==="audio") ? (
        cuesData.map(function(texte, key) {
          return (
            <p key={key}>{texte}</p>
          )
        })
      ) : (<p key="photo">{totem.texte}</p>)
      }
      </div>
    </div>
  </>
);
}

