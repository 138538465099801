
import React, { useRef, useState, useEffect } from "react";

import '../style/Audio.css';

import sx from "mui-sx";
import Fab from '@mui/material/Fab';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as PausePin } from "../images/pause.svg";
import { ReactComponent as PlayPin } from "../images/play.svg";
import { ReactComponent as SelfiePin } from "../images/selfie-zone.svg";

import { CgSpinner } from 'react-icons/cg';

import * as EnvLalique from '../data/espritlalique';

import DisplayVtt from './DisplayVtt';

import { ReactComponent as Pin1} from "../images/pin-el-1.svg";
import { ReactComponent as Pin2} from "../images/pin-el-2.svg";
import { ReactComponent as Pin3} from "../images/pin-el-3.svg";
import { ReactComponent as Pin4} from "../images/pin-el-4.svg";
import { ReactComponent as Pin5} from "../images/pin-el-5.svg";
import { ReactComponent as Pin6} from "../images/pin-el-6.svg";
import { ReactComponent as Pin7} from "../images/pin-el-7.svg";
import { ReactComponent as Pin8} from "../images/pin-el-8.svg";
import { ReactComponent as Pin9} from "../images/pin-el-9.svg";


//VTT 60,60,145

export default function AudioControls({ totemIdx, lang, totemElt, updateDuration, progressBarRef, setAudioText }) {

    const MsgAudio={'FR':"Veuillez appuyer sur le bouton pour lancer l'enregistrement audio", 'EN':"Please press the button to start audio recording", 'NL':"Druk op de knop om audio-opname te starten"};

    const pin_array=[<Pin1/>, <Pin2/>, <Pin3/>, <Pin4/>, <Pin5/>, <Pin6/>, <Pin7/>, <Pin8/>, <Pin9/>];

    const audioRef = useRef(null);
    const trackMetaDataRef = useRef(null);
    let allCues = useRef(null);

    const [isPlay, toggleIsPlay] = useState(false);
    const [isReady, setIsReady] = React.useState(false);

    
    const [VttTexte, setVTTTexte] = useState({type:false, msg:""});

    const timeUpdate = (event) => {
        if(audioRef.current && progressBarRef.current) {
            const currentTime = audioRef.current.currentTime;
            progressBarRef.current.value = currentTime;
            progressBarRef.current.style.setProperty(
                '--range-progress',
                `${((progressBarRef.current.value-1) / audioRef.current.duration) * 100}%`
            );
        }
    }

    function clickOnControl() {
        toggleIsPlay(!isPlay);
        if(!isPlay)
            setVTTTexte({type:true, msg:""});
    }

    useEffect(() => {
        toggleIsPlay(false);
        if(progressBarRef.current) {
            progressBarRef.current.value = 0;
            if(audioRef.current) {
                progressBarRef.current.style.setProperty(
                    '--range-progress',
                    `${((progressBarRef.current.value / audioRef.current.duration) * 100)-15}%`
                );
            }
            else {
                progressBarRef.current.style.setProperty(
                    '--range-progress',
                    `0%`
                );
            }
        }
        if(audioRef.current) {
            if(audioRef.current.readyState===0)
                setIsReady(false);
            else
                setIsReady(true);
        }
        else
            setIsReady(true);
        if(totemElt.type==="photo") {
            setVTTTexte({type:true, msg:totemElt.texte});
        }
    },[totemElt, progressBarRef]);

    useEffect(() => {
        if(audioRef.current) {
            if (isPlay) {
                audioRef.current.play();
            }
            else {
                audioRef.current.pause();
            }
        }
      }, [isPlay, audioRef]);
    
    useEffect(() => {
        if(audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime=0;
            audioRef.current.load();
        }
        toggleIsPlay(false);
    }, [lang]);

    const onCueChange = (event) => {
        if(event.target.track.activeCues.length===1) {
            setVTTTexte({type:false, msg:event.target.track.activeCues[0].text});
        }
    }
    const onLoadedMetadata = () => {
        const seconds = audioRef.current.duration;
        updateDuration(seconds);
        progressBarRef.current.max = seconds;
        if (trackMetaDataRef.current) {
            const { track } = trackMetaDataRef.current;
            allCues = track.cues;
            console.log("onLoadedMetadata trackMetaDataRef allCues length"+allCues.length);
            if(allCues.length===0)
                trackMetaDataRef.current.addEventListener("load", trackloaded);
            else {
                trackloaded();
            }
        }
    };

    const endAudio = () => {
        toggleIsPlay(false);
        setVTTTexte({type:true, msg:MsgAudio[lang]});
    };

    const trackloaded = () => {
        console.log("trackloaded language:"+lang);

        const { track } = trackMetaDataRef.current;
        allCues = track.cues;
        console.log("trackMetaDataRef is ready allCues length"+allCues.length);
        const arrayCues = [];
        for(let c=0;c<allCues.length;c++)
            arrayCues.push(allCues[c].text);
        setAudioText(arrayCues);
        trackMetaDataRef.current.addEventListener("cuechange", onCueChange);
        setVTTTexte({type:true, msg:MsgAudio[lang]});
    }

    return (
        <div className="audio-controls">
            { (totemElt.type==="audio") ?
          (<audio ref={audioRef}  onCanPlay={(e) => {setIsReady(true);}} onEnded={endAudio} onTimeUpdate={timeUpdate} onLoadedMetadata={onLoadedMetadata}>
            <source type="audio/mpeg" src={EnvLalique.AUDIO(totemElt.audio+"_"+lang+".mp3")} />
            <track ref={trackMetaDataRef} default kind="metadata" src={EnvLalique.AUDIO(totemElt.audio+"_"+lang+".vtt")} />
          </audio>): (<div></div>)
          }
          <div className="audio-action">
          { (totemElt.type==="audio") ?
            (<Fab aria-label="Jouer le guide audio" color="primary" onClick={clickOnControl} sx={sx(EnvLalique.sxFabApp, {position:'relative'})}>
            {!isReady ? (
              <CgSpinner size={50} className="animate-spin" />
            ) : 
                isPlay ? 
                (<SvgIcon sx={{fontSize:40}}>
                    <PausePin />
                </SvgIcon>) : 
                (<SvgIcon sx={{fontSize:40}}>
                    <PlayPin />
                </SvgIcon>)}
                
            </Fab>) : (
                <Fab color="primary" aria-label="Selfie" sx={sx(EnvLalique.sxFabApp, {position:'relative'})}>
                    <SvgIcon sx={{fontSize:50}}>
                        <SelfiePin />
                    </SvgIcon>
                </Fab>
            )
          }
          </div>
          <DisplayVtt texte={VttTexte}/>
          <div className="audio-pin">
            <SvgIcon sx={{fontSize:60}}>
                {pin_array[totemIdx-1]}
            </SvgIcon>
          </div>
        </div>
      );
}