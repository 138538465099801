
export default function DisplayVtt({ texte }) {

    return (
        <div className="audio-desc">
            <div className="vtt-zone">
                {texte.type ? (<div className="infoVTT">{texte.msg}</div>) : texte.msg}
            </div>
        </div>
        
      );
}