import React from "react";

export default function Sidebar({ children, duration, state, stylename, defaultStyle, transitionStyles}) {
  return (
    <div
      className={stylename}
      style={{
        ...defaultStyle,
        ...transitionStyles[state]
      }}
    >
      {children}
    </div>
  );
};