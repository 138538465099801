import React from "react";

import { useState, useRef, useEffect} from 'react';


import {
  MapContainer,
  Marker,
  LayerGroup,
  useMapEvents,
  TileLayer} from "react-leaflet";
import { useMap } from 'react-leaflet/hooks'
import AntPath from "../AntPath/AntPathContainer";
import L from "leaflet";

import "leaflet/dist/leaflet.css";
import Fab from '@mui/material/Fab';
import sx from "mui-sx";
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as ExitPin } from "../images/close-circle.svg";

import elPath from '../data/elpath.json';
import listTotem from '../data/timelineData.json';

import * as EnvLalique from '../data/espritlalique';

import Pin1 from "../images/pin-el-1.svg";
import Pin2 from "../images/pin-el-2.svg";
import Pin3 from "../images/pin-el-3.svg";
import Pin4 from "../images/pin-el-4.svg";
import Pin5 from "../images/pin-el-5.svg";
import Pin6 from "../images/pin-el-6.svg";
import Pin7 from "../images/pin-el-7.svg";
import Pin8 from "../images/pin-el-8.svg";
import Pin9 from "../images/pin-el-9.svg";
import PinPhoto from "../images/pin-el-photo.svg";

import Pin1Sel from "../images/pin-el-1-sel.svg";
import Pin2Sel from "../images/pin-el-2-sel.svg";
import Pin3Sel from "../images/pin-el-3-sel.svg";
import Pin4Sel from "../images/pin-el-4-sel.svg";
import Pin5Sel from "../images/pin-el-5-sel.svg";
import Pin6Sel from "../images/pin-el-6-sel.svg";
import Pin7Sel from "../images/pin-el-7-sel.svg";
import Pin8Sel from "../images/pin-el-8-sel.svg";
import Pin9Sel from "../images/pin-el-9-sel.svg";
import PinPhotoSel from "../images/pin-el-photo-sel.svg";

import PinLocation from "../images/pin-location.svg";

const defaultIconSize=[60, 60];
const defaultIconAnchor=[30, 60];
const defaultIconPopup=[30, 30];

export const markers=[
  new L.Icon({
    iconUrl: Pin1,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin2,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin3,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin4,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin5,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin6,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin7,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin8,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin9,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: PinPhoto,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  })
];

export const markersSel=[
  new L.Icon({
    iconUrl: Pin1Sel,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin2Sel,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin3Sel,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin4Sel,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin5Sel,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin6Sel,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin7Sel,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin8Sel,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: Pin9Sel,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  }),
  new L.Icon({
    iconUrl: PinPhotoSel,iconSize: defaultIconSize,iconAnchor: defaultIconAnchor,popupAnchor: defaultIconPopup
  })
];


export default function MapPage({currentTotem, gpsActif, closeModal}) {
  //Mairie : 49.05354419410377, 4.004693840300722
  //https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png
  //https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png
  //https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png
  //https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png
  //https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png

  const mapRef = useRef();

  const zoomvalues=[15, 17, 19];

  const centerZone = { lat: 49.053543843105906, lng: 4.0046730813556 };

  const [map, setMap] = useState(null);
  const [boundMap, setBoundMap] = useState(false);
  const [isBoundDone, setBoundDone] = useState(false);

  const options = { color: "#000000", pulseColor: "#FFF", delay: 750 };
  
  function LocationMarker({ location }) {

    const map = useMap() // Use map events to access the Leaflet map instance
    const [position, setPosition] = useState({lat:0, lng:0});
    /*  lat: listTotem[(currentTotem-1)].pos[0],
      lng: listTotem[(currentTotem-1)].pos[1]
    })*/

    useEffect(() => {
      console.log(location);
      if(location.lat!==0 && location.lng!==0) {

        setPosition({
          lat: location.lat,
          lng: location.lng
        })
        if(!boundMap) {
          let bounds = [];
          bounds.push([location.lat, location.lng])
          listTotem.forEach((totem) => {
            bounds.push(totem.pos)
          })
          map.flyToBounds(bounds, {padding: L.point(36, 36), animate: true,}); 
          setBoundDone(true);
        }
        //
      }
    }, [location])
    

    return position === null ? null : (
      <Marker position={position} icon={new L.Icon({
        iconUrl: PinLocation,iconSize: [50,50],iconAnchor: [25,50],popupAnchor: defaultIconPopup
      })}></Marker>
    )
}

  const [location, setLocation] = useState({lat:0, lng:0});

  useEffect(() => {
    let watchId=null;
    if ("geolocation" in navigator && gpsActif) {
      console.log("GPS Available");
      watchId=navigator.geolocation.watchPosition(function(position) {
        if(position) {
          //console.log("watch Lat: ", position.coords.latitude+" lng: "+position.coords.longitude);
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
        }
      });
      return () => {
        if(watchId) {
          navigator.geolocation.clearWatch(watchId)
        }
      }
    } else {
      console.log("Not Available or GPS is not activated ("+gpsActif+")");
    }
  }, []);

  //
  function CenterComponent() {
    const map = useMap()
    if(currentTotem>0)
      {
      const lonlat=listTotem[(currentTotem-1)].pos;
      map.flyTo({ lat: lonlat[0], lng: lonlat[1] });
    }
    else 
      map.flyTo({ lat: 49.053543843105906, lng: 4.0046730813556 })
  
    const mapEvents = useMapEvents({
        moveend: () => {
          if(isBoundDone)
            setBoundMap(true);
        },
    });


    return null
  }


  function selectMarkerOnMap(idx) {
    closeModal((idx+1));
  }
return (
  <>
   <Fab aria-label="Fermer" color="primary" onClick={function() {closeModal(-1);}} sx={sx(EnvLalique.sxFabApp, {top: 10,right:10})}>
        <SvgIcon sx={{fontSize:45}}>
          <ExitPin />
        </SvgIcon>
    </Fab>
   <div>

    
   <MapContainer
        style={{ height: "100vh", width: "100%" }}
        center={centerZone}
        ref={mapRef}
        zoom={15}
        whenReady={setMap}
        scrollWheelZoom={true}
      >
        <CenterComponent />
        {gpsActif?<LocationMarker location={location} />:null}
        
        <AntPath positions={elPath} options={options} />
        <LayerGroup>
        {listTotem.map((item, idx) =>
          {
            if(idx===(currentTotem-1)) {
              if(item.type==="photo") {
                return (<Marker key={idx} position={item.pos} icon={markersSel[9]} eventHandlers={{
                  click: () => {
                    selectMarkerOnMap(idx);
                  },
                }}></Marker>)
              }
              else
                return (<Marker key={idx} position={item.pos} icon={markersSel[idx]} eventHandlers={{
                  click: () => {
                    selectMarkerOnMap(idx);
                  },
                }}></Marker>)
            }
            else
              if(item.type==="photo") {
                return (<Marker key={idx} position={item.pos} icon={markers[9]} eventHandlers={{
                  click: () => {
                    selectMarkerOnMap(idx);
                  },
                }}></Marker>)
              }
              else
                return (<Marker key={idx} position={item.pos} icon={markers[idx]} eventHandlers={{
                  click: () => {
                    selectMarkerOnMap(idx);
                  },
                }}></Marker>)
          }
        )}
        </LayerGroup>
        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
    </MapContainer>
   </div>
  </>
);
}

